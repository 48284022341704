//services...
const dev = {
  tnAdmin: process.env.VUE_APP_BASE_URL_ADMIN,
  totem: process.env.VUE_APP_BASE_URL_TOTEM
}

const homolog = {
  tnAdmin: process.env.VUE_APP_BASE_URL_ADMIN,
  totem: process.env.VUE_APP_BASE_URL_TOTEM
}

const prod = {
  tnAdmin: process.env.VUE_APP_BASE_URL_ADMIN,
  totem: process.env.VUE_APP_BASE_URL_TOTEM
}
const baseUrls = {dev, homolog, prod}
const href = window.location.href
const isDev = href.includes('localhost') ? 'dev' : null
const isHomolog = href.includes('homolog') ? 'homolog' : null
const environment = isDev || isHomolog || 'prod'
const getBaseUrl = (env = environment) => baseUrls[env]

export default getBaseUrl()
